<template>
    <CustomDialog v-model="show">
        <template #header>
            <div class="text-lg font-medium text-gray-370 dark:text-white uppercase">
                Create New Workspace
            </div>
        </template>

        <div
            class="max-mob:px-4 px-7.5 max-mob:py-4 py-5 max-mob:min-w-0 max-mob:w-full min-w-99.5"
            @keydown.enter="submitForm"
        >
            <div class="text-gray-370 dark:text-white text-xs font-medium mb-3.75">
                Add name to create workspace
            </div>

            <FormInput
                v-model="formData.name.value"
                v-model:valid="formData.name.valid"
                :rules="rules.default"
                placeholder="Type Workspace Name"
                autoFocus
            />
        </div>

        <template #footer>
            <div class="flex items-center justify-end w-full gap-x-5">
                <TextBtn @click="closeDialog">Cancel</TextBtn>

                <PrimaryBtn
                    :loading="loading"
                    @click="submitForm"
                >
                    Create
                </PrimaryBtn>
            </div>
        </template>
    </CustomDialog>
</template>

<script setup>
import {
    computed, reactive, ref, watch,
} from 'vue'

import CustomDialog from '@/components/dialogs/CustomDialog.vue'
import FormInput from '@/components/inputs/FormInput.vue'
import PrimaryBtn from '@/components/buttons/PrimaryBtn.vue'
import TextBtn from '@/components/buttons/TextBtn.vue'

import { checkFormValidity, collectFormData } from '@/helpers/app-helper'
import { createWorkspace } from '@/api/workspace/workspace-routes'

import { useAuthorizationStore } from '@/store/authorizationStore'
import { useDefaultStore } from '@/store/defaultStore'

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    isNotWorkspace: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:modelValue', 'goToReporting'])

const defaultStore = useDefaultStore()
const authorizationStore = useAuthorizationStore()

const show = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    },
})

const loading = ref(false)

const formData = reactive({
    name: {
        value: '',
        valid: false,
    },
})

const rules = reactive({
    default: [],
})

async function submitForm() {
    await setRules()

    const valid = checkFormValidity(formData)

    if (valid) {
        createNewWorkspace()
    }
}

function setRules() {
    rules.default = [
        (v) => !!v || 'This field is required',
    ]
}

function closeDialog() {
    show.value = false
}

async function createNewWorkspace() {
    loading.value = true

    const { success, payload, message } = await createWorkspace(collectFormData(formData))

    if (success) {
        closeDialog()
        defaultStore.setSuccessMessage({ message })

        authorizationStore.addWorkspace(payload)
        authorizationStore.saveWorkspace(payload)
        await authorizationStore.getCheckWorkspace(payload.slug)

        if (props.isNotWorkspace) {
            await emits('goToReporting')
        }
    } else {
        defaultStore.setErrorMessage({ message })
    }

    loading.value = false
}

function clearForm() {
    formData.name.value = ''
    rules.default = []
}

watch(show, (value) => {
    if (!value) {
        clearForm()
    }
})
</script>
