const VIEWS_OPTIONS = {
    setupTrackingNumber: {
        view: 'setupTrackingNumber',
        title: 'Manage Tracking Numbers',
        backAction: 'backToManagePage',
    },
    setupNewNumberPool: {
        view: 'setupNewNumberPool',
        title: 'Edit Tracking Number',
        backAction: 'backToSetupTrackingNumber',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Number pool has been created, but has not been attached to the Tracking Number',
        },
    },
    addTrackingNumbersToNumberPool: {
        view: 'addTrackingNumbersToNumberPool',
        title: 'Edit Number Pool',
        backAction: 'backToSetupCampaign',
    },
    buyTrackingNumbersToNumberPool: {
        view: 'buyTrackingNumbersToNumberPool',
        title: 'Buy Numbers to the Number Pool',
        backAction: 'backToSetupCampaign',
    },
    setupNewTrafficSource: {
        view: 'setupNewTrafficSource',
        title: 'Edit Tracking Number',
        backAction: 'backToSetupTrackingNumber',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Traffic Source has been created, but has not been attached to the Tracking Number',
        },
    },
    detailTrafficSource: {
        view: 'detailTrafficSource',
        title: 'Edit Tracking Number',
        backAction: 'backToSetupTrackingNumber',
    },
    setupNewVendor: {
        view: 'setupNewVendor',
        title: 'Edit Tracking Number',
        backAction: 'backToSetupTrackingNumber',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Vendor has been created, but has not been attached to the Tracking Number',
        },
    },
    detailVendor: {
        view: 'detailVendor',
        title: 'Edit Tracking Number',
        backAction: 'backToSetupTrackingNumber',
    },
}

export {
    VIEWS_OPTIONS,
}
