export const ROUTES = {
    auth: 'Auth',
    authLogin: 'AuthLogin',
    authReset: 'AuthReset',
    authSetNewPassword: 'AuthSetNewPassword',
    register: 'Register',
    inviteUser: 'InviteUser',
    reporting: 'Reporting',
    manageCampaigns: 'ManageCampaigns',
    detailCampaigns: 'DetailCampaigns',
    manageTrafficSources: 'ManageTrafficSources',
    detailTrafficSource: 'DetailTrafficSource',
    manageVendors: 'ManageVendors',
    detailVendor: 'DetailVendor',
    manageBuyers: 'ManageBuyers',
    detailBuyer: 'DetailBuyer',
    manageDestinationGroups: 'ManageDestinationGroups',
    detailDestinationGroup: 'DetailDestinationGroup',
    manageDestinations: 'ManageDestinations',
    detailDestination: 'DetailDestination',
    manageNumbers: 'ManageNumbers',
    buyNumbers: 'BuyNumbers',
    detailNumber: 'DetailNumber',
    blockNumber: 'BlockNumber',
    managePools: 'ManagePools',
    detailPool: 'DetailPool',
    manageWorkspace: 'ManageWorkspace',
    detailWorkspaceRole: 'DetailWorkspaceRole',
    gettingStart: 'GettingStart',
    accountSetting: 'AccountSetting',
    userSetting: 'UserSetting',
    password: 'Password',
    rechargeBalance: 'RechargeBalance',
    billingProfile: 'BillingProfile',
    twoFactorAuth: 'TwoFactorAuth',
    loginSecurity: 'LoginSecurity',
    paymentHistory: 'PaymentHistory',
    apiAccessTokens: 'ApiAccessTokens',
    protection: 'Protection',
    manageCards: 'ManageCards',
    workspaceActivity: 'WorkspaceActivity',
    detailWorkspaceActivity: 'DetailWorkspaceActivity',
    notFoundWorkspace: 'NotFoundWorkspace',
    notFound: 'NotFound',
    accountDisabled: 'AccountDisabled',
    verification: 'verification',
    whiteLabel: 'WhiteLabel',
    manageIntegrations: 'ManageIntegrations',
    googleAdsIntegration: 'GoogleAdsIntegration',
    googleAdsIntegrationTrafficSource: 'GoogleAdsIntegrationTrafficSource',
    webhooksIntegration: 'WebhooksIntegration',
    detailWebhookIntegration: 'DetailWebhookIntegration',
    callFlows: 'CallFlows',
    detailCallFlows: 'DetailCallFlows',
    voipShield: 'VoipShield',
    detailVoipShield: 'detailVoipShield',
}
