import { defineStore } from 'pinia'

import {
    getDetailNumber, updateNumber, buyNumber, buyNumberForVendor,
} from '@/api/number-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'

import { VIEWS_OPTIONS } from '@/constants/call-tracking/trackingNumber/trackingNumberSettingsOptions'

export const useDetailTrackingNumbersStore = defineStore('detailTrackingNumbers', {
    state: () => ({
        slug: null,
        currentView: VIEWS_OPTIONS.setupTrackingNumber,
        loading: false,
        actionLoading: false,
        formData: {},
        detailData: {},
    }),

    getters: {},

    actions: {
        setTrackingNumberSlug(slug) {
            this.slug = slug
        },

        async goToLoadDetailTrackingNumber() {
            const defaultStore = useDefaultStore()

            this.loading = true

            const { success, payload, message } = await getDetailNumber(this.slug)

            if (success) {
                this.parsePayload(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false

            return success
        },

        parsePayload(payload) {
            this.detailData = payload

            this.formData.number = payload.slug
            this.formData.name = payload.name
            this.formData.status_id = payload.status_id
            this.formData.traffic_source = payload.traffic_source ? payload.traffic_source.slug : null
            this.formData.vendor = payload.vendor ? payload.vendor.slug : null
            this.formData.conversion = payload.conversion ? payload.conversion.slug : null

            this.formData.cap_enabled = payload.cap_enabled
            this.formData.cap_on_id = payload.cap_on_id
            this.formData.global_cap = payload.global_cap
            this.formData.monthly_cap = payload.monthly_cap
            this.formData.daily_cap = payload.daily_cap
            this.formData.hourly_cap = payload.hourly_cap

            this.formData.max_concurrency_enabled = payload.max_concurrency_enabled
            this.formData.max_concurrency = payload.max_concurrency
        },

        async goToUpdateTrackingNumber(formDataForUpdate = {}) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            this.formData = {
                ...this.formData,
                ...formDataForUpdate,
            }

            const { success, payload, message } = await updateNumber({
                formData: this.formData,
                slug: this.slug,
            })

            if (success) {
                this.detailData = payload

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success, payload, message }
        },

        async goToBuyNumber(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await buyNumber(formData)

            if (success) {
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToBuyNumberForVendor(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await buyNumberForVendor(formData)

            if (success) {
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        openCreateVendor() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.setupNewVendor

            breadCrumbsStore.addItem({ title: 'Setup Vendor', view: VIEWS_OPTIONS.setupNewVendor })
        },

        openDetailVendor() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.detailVendor

            breadCrumbsStore.addItem({ title: 'Detail Vendor', view: VIEWS_OPTIONS.detailVendor })
        },

        openSetupNewNumberPool() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.setupNewNumberPool

            breadCrumbsStore.addItem({ title: 'Setup Number Pool', view: VIEWS_OPTIONS.setupNewNumberPool })
        },

        openDetailNumberPool() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.detailNumberPool

            breadCrumbsStore.addItem({ title: 'Detail Number Pool', view: VIEWS_OPTIONS.detailNumberPool })
        },

        openAddTrackingNumbersToPool() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.addTrackingNumbersToNumberPool

            breadCrumbsStore.addItem({ title: 'Add Numbers to Pool', view: VIEWS_OPTIONS.addTrackingNumbersToNumberPool })
        },

        openBuyTrackingNumbersToPool() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.buyTrackingNumbersToNumberPool

            breadCrumbsStore.addItem({ title: 'Add Numbers to Pool', view: VIEWS_OPTIONS.buyTrackingNumbersToNumberPool })
        },

        openSetupNewTrafficSource() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.setupNewTrafficSource

            breadCrumbsStore.addItem({ title: 'Setup Traffic Source', view: VIEWS_OPTIONS.setupNewTrafficSource })
        },

        openDetailTrafficSource() {
            const breadCrumbsStore = useBreadCrumbsStore()

            this.currentView = VIEWS_OPTIONS.detailTrafficSource

            breadCrumbsStore.addItem({ title: 'Detail Traffic Source', view: VIEWS_OPTIONS.detailTrafficSource })
        },

        setCurrentView(item) {
            if (item.hasOwnProperty('view')) {
                this.currentView = item.view

                return
            }

            if (item.hasOwnProperty('routeName')) {
                this.router.push({ name: item.routeName })
            }
        },
    },
})
