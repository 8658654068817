import { CONVERSIONS_OPTIONS } from '@/constants/destinationConversionOptions'
import { TYPE_NUMBER } from '@/constants/call-tracking/campaign/typeNumber'

const FILTER_STATUS = {
    label: 'Status',
    key: 'status_id',
    type: 'list',
    values: [
        {
            id: 1,
            name: 'Active',
        },
        {
            id: 2,
            name: 'Paused',
        },
    ],
}

const FILTER_TYPE_NUMBER = {
    label: 'Type',
    key: 'type',
    type: 'list',
    values: [
        {
            id: TYPE_NUMBER.STATIC,
            name: 'Static',
        },
        {
            id: TYPE_NUMBER.DYNAMIC,
            name: 'Dynamic',
        },
    ],
}

const FILTER_STATUS_CUSTOM = {
    ...FILTER_STATUS,
    custom: true,
}

const FILTER_ACTIVE = {
    label: 'Status',
    key: 'active',
    type: 'list',
    values: [
        {
            id: 1,
            name: 'Active',
        },
        {
            id: 2,
            name: 'Paused',
        },
    ],
}

const FILTER_ROLE = {
    label: 'Role',
    key: 'role',
    type: 'list',
    values: [],
}

const FILTER_NAME = {
    label: 'Name',
    key: 'name',
    type: 'string',
    values: [],
}

const FILTER_INVITE_STATUS = {
    label: 'Status',
    key: 'accepted',
    type: 'boolean',
    values: [
        {
            label: 'Registered',
            value: 1,
        },
        {
            label: 'Pending',
            value: 0,
        },
    ],
}

const FILTER_URL_PARAM = {
    label: 'Url param',
    key: 'url_param',
    type: 'string',
    values: [],
}

const FILTER_EMAIL = {
    label: 'Email',
    key: 'email',
    type: 'string',
    values: [],
}

const FILTER_BUYER = {
    label: 'Buyer',
    key: 'buyer_id',
    type: 'list',
    values: [],
}

const FILTER_DESTINATION = {
    label: 'Destination',
    key: 'destination',
    type: 'string',
    values: [],
}

const FILTER_TYPE_DESTINATION = {
    label: 'Type',
    key: 'pivot.convert_on_id',
    type: 'list',
    values: [
        {
            id: CONVERSIONS_OPTIONS.converted.id,
            name: CONVERSIONS_OPTIONS.converted.name,
        },
        {
            id: CONVERSIONS_OPTIONS.length.id,
            name: CONVERSIONS_OPTIONS.length.name,
        },
        // {
        //     id: CONVERSIONS_OPTIONS.webhooks.id,
        //     name: CONVERSIONS_OPTIONS.webhooks.name,
        // },
    ],
}

const FILTER_REVENUE_DESTINATION = {
    label: 'Revenue',
    key: 'pivot.revenue',
    type: 'number',
    values: [],
}

const FILTER_NUMBER_POOL_TRACKING_NUMBER = {
    label: 'Number pool',
    key: 'slave_pool.slug',
    type: 'list',
    values: [],
}

const FILTER_VENDOR_TRACKING_NUMBER = {
    label: 'Vendor',
    key: 'vendor.slug',
    type: 'list',
    values: [],
}

const FILTER_TRAFFIC_SOURCE_TRACKING_NUMBER = {
    label: 'Traffic source',
    key: 'traffic_source.slug',
    type: 'list',
    values: [],
}

const FILTER_VENDOR_PAYOUT_TRACKING_NUMBER = {
    label: 'Payout',
    key: 'vendor.pivot.payout',
    type: 'number',
    values: [],
}

export {
    FILTER_STATUS,
    FILTER_TYPE_NUMBER,
    FILTER_STATUS_CUSTOM,
    FILTER_ACTIVE,
    FILTER_ROLE,
    FILTER_NAME,
    FILTER_INVITE_STATUS,
    FILTER_URL_PARAM,
    FILTER_EMAIL,
    FILTER_BUYER,
    FILTER_DESTINATION,
    FILTER_TYPE_DESTINATION,
    FILTER_REVENUE_DESTINATION,
    FILTER_NUMBER_POOL_TRACKING_NUMBER,
    FILTER_VENDOR_TRACKING_NUMBER,
    FILTER_TRAFFIC_SOURCE_TRACKING_NUMBER,
    FILTER_VENDOR_PAYOUT_TRACKING_NUMBER,
}
