const VIEWS_OPTIONS = {
    setupNumberPool: {
        view: 'setupNumberPool',
        title: 'Number Pools',
    },
    detailVendor: {
        view: 'detailVendor',
        title: 'Setup Vendor',
    },
    detailTrafficSource: {
        view: 'detailTrafficSource',
        title: 'Setup Traffic Source',
    },
    addTrackingNumbers: {
        view: 'addTrackingNumbers',
        title: 'Add Tracking Numbers',
    },
    addTrafficSources: {
        view: 'addTrafficSources',
        title: 'Add Traffic Sources',
    },
    buyTrackingNumbers: {
        view: 'buyTrackingNumbers',
        title: 'Buy Tracking Numbers',
    },
    attachTrafficSource: {
        view: 'attachTrafficSource',
        title: 'Setup Traffic Source',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Traffic Source has been created, but has not been attached to the Number Pool',
        },
    },
    attachVendor: {
        view: 'attachVendor',
        title: 'Setup Vendor',
        confirmation: true,
        dialogOptions: {
            title: 'Are you sure you want to exit this setting?<br />Vendor has been created, but has not been attached to the Number Pool',
        },
    },
}

export {
    VIEWS_OPTIONS,
}
