import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getPoolsAll = () => axios.get(`${getWorkspaceId()}/number-pools/all`)
const getPoolsForManager = (params) => axios.get(`${getWorkspaceId()}/number-pools/paginated`, { params })
const getPoolsForVendor = (params) => axios.get(`${getWorkspaceId()}/vendor/number-pools/paginated`, { params })

const getAvailableNumbersPools = (slug) => axios.get(`${getWorkspaceId()}/number-pools/${slug}/available-numbers`)
const createPool = (formData) => axios.post(`${getWorkspaceId()}/number-pools`, formData)
const updatePool = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}`, formData)
const deletePool = (data) => axios.delete(`${getWorkspaceId()}/number-pools`, { data })
const autoBuyPool = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}/buy-numbers`, formData)
const detachPool = ({ slug, data }) => axios.delete(`${getWorkspaceId()}/number-pools/${slug}/reset-numbers`, { data })
const getDetailPool = (slug) => axios.get(`${getWorkspaceId()}/number-pools/${slug}`)
const getAvailableTrafficSources = (slug) => axios.get(`${getWorkspaceId()}/number-pools/${slug}/traffic-source`)
const attachTrafficSourceToPool = (slug, formData) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}/traffic-source`, formData)
const detachTrafficSourceFromPool = (slug, traffic_source_slugs) => axios.delete(`${getWorkspaceId()}/number-pools/${slug}/traffic-source`, { data: { traffic_source_slugs } })
const updateTrafficSourcePool = (slug, formData) => axios.put(`${getWorkspaceId()}/number-pools/${slug}/traffic-source`, formData)

const getJsCodeForManager = ({ slug }) => axios.get(`${getWorkspaceId()}/number-pools/${slug}/integration-codes`)
const getJsCodeForVendor = ({ slug }) => axios.get(`${getWorkspaceId()}/vendor/number-pools/${slug}/integration-codes`)
const downloadDNIIntegrationFileForManager = ({ slug }) => axios.get(`${getWorkspaceId()}/number-pools/${slug}/integration-file`)
const downloadDNIIntegrationFileForVendor = ({ slug }) => axios.get(`${getWorkspaceId()}/vendor/number-pools/${slug}/integration-file`)

const attachNumbersToPool = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}/attach-numbers`, formData)
const buyAndAttachNumbersToPool = ({ slug, formData }) => axios.post(`${getWorkspaceId()}/number-pools/${slug}/phone-numbers`, formData)
const detachNumbersToPool = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/number-pools/${slug}/detach-numbers`, { data })

const updateNumberPoolStatus = (formData) => axios.patch(`${getWorkspaceId()}/number-pools/status`, formData)

const attachPoolToNumber = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}/attach-to-number`, formData)
const detachPoolFromNumber = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/number-pools/${slug}/detach-from-number`, { data })

const getPoolFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/number-pools/filters-and-sorting`)

export {
    getPoolsAll,
    getAvailableNumbersPools,
    getPoolsForManager,
    getPoolsForVendor,
    createPool,
    updatePool,
    deletePool,
    autoBuyPool,
    detachPool,
    getDetailPool,
    getJsCodeForManager,
    getJsCodeForVendor,
    attachNumbersToPool,
    detachNumbersToPool,
    attachPoolToNumber,
    detachPoolFromNumber,
    buyAndAttachNumbersToPool,
    getPoolFiltersAndSorting,
    downloadDNIIntegrationFileForManager,
    downloadDNIIntegrationFileForVendor,
    getAvailableTrafficSources,
    attachTrafficSourceToPool,
    detachTrafficSourceFromPool,
    updateNumberPoolStatus,
    updateTrafficSourcePool,
}
