import ReportingIcon from '@/components/icons/navigations/ReportingIcon.vue'
import CampaignIcon from '@/components/icons/navigations/CampaignIcon.vue'
import PhoneNumberIcon from '@/components/icons/navigations/PhoneNumberIcon.vue'
import DestinationIcon from '@/components/icons/navigations/DestinationIcon.vue'
import VendorIcon from '@/components/icons/navigations/VendorIcon.vue'
import BuyerIcon from '@/components/icons/navigations/BuyerIcon.vue'
import TrafficSourceIcon from '@/components/icons/navigations/TrafficSourceIcon.vue'
import CallFlowsIcon from '@/components/icons/navigations/CallFlowsIcon.vue'
import IntegrationIcon from '@/components/icons/navigations/IntegrationIcon.vue'
import SuppressionListIcon from '@/components/icons/navigations/SuppressionListIcon.vue'

import { ROUTES } from '@/constants/routes'
import { ROUTER_PARAMS_NAMES } from '@/constants/routerParamsNames'
import { PERMISSION_NAMES } from '@/constants/workspace/permissionSettings'

const reporting = {
    id: 1,
    name: 'Reporting',
    routeName: ROUTES.reporting,
    paramsNames: [ROUTER_PARAMS_NAMES.workspace],
    permissions: [PERMISSION_NAMES.view_reporting],
    icon: () => ReportingIcon,
}

export const CALL_MENU_ROUTES_MANAGER = [
    reporting,
    {
        id: 2,
        name: 'Campaigns',
        routeName: ROUTES.manageCampaigns,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_campaigns],
        icon: () => CampaignIcon,
    },
    {
        id: 12,
        name: 'Call Flows',
        routeName: ROUTES.callFlows,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_call_flows],
        icon: () => CallFlowsIcon,
    },
    {
        id: 3,
        name: 'Phone Numbers',
        icon: () => PhoneNumberIcon,
        isOpenSub: false,
        isFirstLoad: true,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_phone_numbers, PERMISSION_NAMES.view_phone_number_pools],
        subMenu: [
            {
                name: 'Tracking Numbers',
                routeName: ROUTES.manageNumbers,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
                permissions: [PERMISSION_NAMES.view_phone_numbers],
            },
            {
                name: 'Number Pools',
                routeName: ROUTES.managePools,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
                permissions: [PERMISSION_NAMES.view_phone_number_pools],
            },
        ],
    },
    {
        id: 4,
        name: 'Destinations',
        icon: () => DestinationIcon,
        isOpenSub: false,
        isFirstLoad: true,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_destinations, PERMISSION_NAMES.view_destination_groups],
        subMenu: [
            {
                name: 'Manage Destinations',
                routeName: ROUTES.manageDestinations,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
                permissions: [PERMISSION_NAMES.view_destinations],
            },
            {
                name: 'Manage Groups',
                routeName: ROUTES.manageDestinationGroups,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
                permissions: [PERMISSION_NAMES.view_destination_groups],
            },
        ],
    },
    {
        id: 5,
        name: 'Buyers',
        routeName: ROUTES.manageBuyers,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_buyers],
        icon: () => BuyerIcon,
    },
    {
        id: 7,
        name: 'Vendors',
        routeName: ROUTES.manageVendors,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_vendors],
        icon: () => VendorIcon,
    },
    {
        id: 9,
        name: 'Traffic Sources',
        routeName: ROUTES.manageTrafficSources,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_traffic_sources],
        icon: () => TrafficSourceIcon,
    },
    {
        id: 10,
        name: 'Integrations',
        routeName: ROUTES.manageIntegrations,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_integrations],
        icon: () => IntegrationIcon,
    },
    {
        id: 11,
        name: 'Suppression List',
        icon: () => SuppressionListIcon,
        isOpenSub: false,
        isFirstLoad: true,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        permissions: [PERMISSION_NAMES.view_voip_black_lists, PERMISSION_NAMES.view_voip_black_lists],
        subMenu: [
            {
                name: 'VoIP Shield',
                routeName: ROUTES.voipShield,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
                permissions: [PERMISSION_NAMES.view_voip_black_lists],
            },
            {
                name: 'Blocked Numbers',
                routeName: ROUTES.blockNumber,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
                permissions: [PERMISSION_NAMES.view_voip_black_lists],
            },
        ],
    },
]

export const CALL_MENU_ROUTES_BUYER = [
    reporting,
    {
        id: 4,
        name: 'Destinations',
        routeName: ROUTES.manageDestinations,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        icon: () => DestinationIcon,
    },
]

export const CALL_MENU_ROUTES_VENDOR = [
    reporting,
    {
        id: 3,
        name: 'Phone Numbers',
        icon: () => PhoneNumberIcon,
        isOpenSub: false,
        paramsNames: [ROUTER_PARAMS_NAMES.workspace],
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Tracking Numbers',
                routeName: ROUTES.manageNumbers,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
            },
            {
                name: 'Number Pools',
                routeName: ROUTES.managePools,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
            },
        ],
    },
]
