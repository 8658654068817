import { readonly } from 'vue'

const PERMISSION_GROUP = {
    reporting: 'Reporting',
}

const PERMISSION_ACTIONS = {
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
}

const PERMISSION_NAMES = readonly({
    view_blocked_numbers: 'view_blocked_numbers',
    view_buyers: 'view_buyers',
    view_call_flows: 'view_call_flows',
    view_campaigns: 'view_campaigns',
    view_destination_groups: 'view_destination_groups',
    view_destinations: 'view_destinations',
    view_integrations: 'view_integrations',
    view_phone_number_pools: 'view_phone_number_pools',
    view_phone_numbers: 'view_phone_numbers',
    view_reporting: 'view_reporting',
    view_traffic_sources: 'view_traffic_sources',
    view_vendors: 'view_vendors',
    view_voip_black_lists: 'view_voip_black_lists',

    edit_blocked_numbers: 'edit_blocked_numbers',
    edit_buyers: 'edit_buyers',
    edit_call_flows: 'edit_call_flows',
    edit_campaigns: 'edit_campaigns',
    edit_destination_groups: 'edit_destination_groups',
    edit_destinations: 'edit_destinations',
    edit_integrations: 'edit_integrations',
    edit_phone_number_pools: 'edit_phone_number_pools',
    edit_phone_numbers: 'edit_phone_numbers',
    edit_reporting: 'edit_reporting',
    edit_traffic_sources: 'edit_traffic_sources',
    edit_vendors: 'edit_vendors',
    edit_voip_black_lists: 'edit_voip_black_lists',

    delete_blocked_numbers: 'delete_blocked_numbers',
    delete_buyers: 'delete_buyers',
    delete_call_flows: 'delete_call_flows',
    delete_campaigns: 'delete_campaigns',
    delete_destination_groups: 'delete_destination_groups',
    delete_destinations: 'delete_destinations',
    delete_integrations: 'delete_integrations',
    delete_phone_number_pools: 'delete_phone_number_pools',
    delete_phone_numbers: 'delete_phone_numbers',
    delete_traffic_sources: 'delete_traffic_sources',
    delete_vendors: 'delete_vendors',
    delete_voip_black_lists: 'delete_voip_black_lists',

    listen_to_recordings: 'listen_to_recordings',

    // for buyer
    set_capacity: 'set_capacity',
    set_concurrency: 'set_concurrency',
    pause_destinations: 'pause_destinations',

    // for vendor
    create_numbers: 'create_numbers',
    see_record: 'see_record',

    // for buyer and vendor
    hangup_calls: 'hangup_calls',
    block_numbers: 'block_numbers',
    download_reports: 'download_reports',
})

export {
    PERMISSION_GROUP,
    PERMISSION_ACTIONS,
    PERMISSION_NAMES,
}
